import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import LanguageGuidesOne from "@containers/language-guides/layout-1";
import LanguageGuidesTwo from "@containers/language-guides/layout-2";
import CaseStudy from "@containers/global/case-study";
import { caseStudiesData } from "@constants";
import QuoteForm from "@containers/common-quote-form";

const LanguageGuidesPage = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo title="Language Guides" 
        />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["language-page-header-data"]} />
        <QuoteForm />
        <LanguageGuidesOne data={content["languages-asian-body"]} />
        <LanguageGuidesOne
          layout={2}
          data={content["language-western-european-body"]}
        />
        <LanguageGuidesOne
          layout={3}
          data={content["languages-eastern-central-european-body"]}
        />
        <LanguageGuidesOne layout={4} data={content["language-nordic-body"]} />
        <LanguageGuidesTwo
          layout={5}
          middleeastdata={content["language-middle-east-body"]}
          americasdata={content["language-americas-body"]}
        />
        {/* <LanguageGuidesTwo
          layout={6}
          data={content["language-americas-body"]}
        /> */}

        <CaseStudy data={caseStudiesData} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query languagePageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "languages" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
  }
`;

LanguageGuidesPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default LanguageGuidesPage;
